<template>
  <b-row>
    <Confirmation ref="Confirmation"></Confirmation>
    <b-col md="12" lg="12">
      <h1>{{$t('menu.potential-duplicates')}}</h1>
      <div class="separator mb-5"></div>
      <div>
        <b-row>
          <b-colxx xl="7">
            <h1 @click="gotToNewCompany()" class="title-link">{{ companyID }}: {{ company.companyLegalName }}</h1>
          </b-colxx>
        </b-row>
      </div>
      <div class="title-and-right-element">
        <b-button
          variant="danger"
          size="lg"
          class="top-right-button mr-2"
          @click="closeNewLead()"
        >Close New Lead</b-button>
      </div>
      <b-overlay
        :show="gridLoading"
        variant="transparent"
        blur="5px"
        opacity="0.9"
      >
        <b-card class="mb-4">
          <vuetable
            ref="vuetable"
            :api-mode="false"
            :fields="fields"
            :data-total="dataCount"
            :data-manager="dataManager"
            :css="css.table"
            @vuetable:loading="onVuetableLoading"
            @vuetable:loaded="onVuetableLoaded"
            @vuetable:cell-rightclicked="rightClicked"
            :noDataTemplate="'This Lead is in-active or does not exist.'">
            <template slot="jsonData" slot-scope="props">
              <JsonObjectFormatter
                :jsonData="props.rowData.jsonData">
              </JsonObjectFormatter>
            </template>
          </vuetable>
        </b-card>
      </b-overlay>
      <v-contextmenu ref="contextmenu">
        <v-contextmenu-item @click="onContextMenuAction('view')">
          <span>View Existing Lead</span>
        </v-contextmenu-item>
        <v-contextmenu-item @click="onContextMenuAction('closeLead')">
          <span>Close Existing Lead</span>
        </v-contextmenu-item>
        <v-contextmenu-item @click="onContextMenuAction('noAction')">
          <span>Acknowledge and take no action</span>
        </v-contextmenu-item>
      </v-contextmenu>
    </b-col>
  </b-row>
</template>
<script>

import Confirmation from '../../../components/Common/Confirmation.vue'
import CssTableConfig from '../../../constants/VuetableBootstrapconfig'
import CompanyMixin from '../../../mixins/CompanyMixin.vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import JsonObjectFormatter from '../../../components/Common/Company/JsonObjectFormatter.vue'
import moment from 'moment/moment.js'

export default {
  props: ['companyID'],
  components: {
    vuetable: Vuetable,
    JsonObjectFormatter: JsonObjectFormatter,
    Confirmation: Confirmation
  },

  mixins: [CompanyMixin],

  data () {
    return {
      dataCount: 0,
      css: CssTableConfig,
      gridLoading: false,
      searchText: '',
      fields: [
        {
          name: 'existingCompanyID',
          title: 'Company ID',
          titleClass: '',
          dataClass: 'text-muted',
          width: '5%'
        },
        {
          name: 'companyName',
          sortField: 'companyName',
          title: 'Company Name',
          titleClass: '',
          dataClass: 'text-muted',
          width: '7%'
        },
        {
          name: 'dateCreated',
          sortField: 'dateCreated',
          title: 'Date Created',
          titleClass: '',
          dataClass: 'text-muted',
          width: '5%',
          callback: this.formatDate
        },
        {
          name: 'dateAcknowledged',
          sortField: 'dateAcknowledged',
          title: 'Date Acknowledged',
          titleClass: '',
          dataClass: 'text-muted',
          width: '5%',
          callback: this.formatDate
        },
        {
          name: 'employeeName',
          sortField: 'employeeName',
          title: 'Acknowledged By',
          titleClass: '',
          dataClass: 'text-muted',
          width: '7%'
        },
        {
          name: '__slot:jsonData',
          title: 'Duplicate Data',
          titleClass: '',
          dataClass: 'text-muted',
          width: '20%'
        }
      ]
    }
  },

  async created () {
    await this.getCompanyByID(this.companyID)
    await this.getCompanyDuplicates(this.companyID)
    this.refreshVueTable()
  },

  methods: {
    async closeNewLead () {
      await this.UpdateCompanyDuplicate({ newCompanyID: this.companyID }, 'new')
      this.refreshVueTable()
    },
    async refreshVueTable () {
      await this.getCompanyDuplicates(this.companyID)
      this.$refs.vuetable.refresh()
    },
    rightClicked (dataItem, field, event) {
      event.preventDefault()
      this.rightClickedItem = dataItem
      this.$refs.contextmenu.show({ top: event.pageY, left: event.pageX })
    },
    gotToNewCompany () {
      var routeData = this.$router.resolve({ name: 'EditCompany', params: { companyID: this.companyID } })
      window.open(routeData.href, '_blank')
    },
    async onContextMenuAction (action) {
      var rightClickedItem = this.rightClickedItem
      rightClickedItem.jsonData = ''
      switch (action) {
        case 'view':
          var routeData = this.$router.resolve({ name: 'EditCompany', params: { companyID: rightClickedItem.existingCompanyID } })
          window.open(routeData.href, '_blank')
          break
        case 'closeLead':
          await this.UpdateCompanyDuplicate(rightClickedItem, 'old')
          this.refreshVueTable()

          break
        case 'noAction':
          await this.UpdateCompanyDuplicate(rightClickedItem, 'acknowledge')
          this.refreshVueTable()

          break
        default:
          break
      }
    },
    onVuetableLoading () {
      this.gridLoading = true
    },
    onVuetableLoaded () {
      this.gridLoading = false
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    dataManager (sortOrder, pagination) {
      const data = this.searchResults
      return {
        data
      }
    },
    viewSearchResult (companyID) {
      this.$router.push({ name: 'EditCompany', params: { companyID: companyID } })
    },
    formatDate (value) {
      if (value === null) {
        return ''
      } else {
        return moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY')
      }
    }
  }
}
</script>
<style scoped>
.title-link:hover {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}
</style>
